.container{
    max-width: 600px;
    margin: 0 auto;
}

.add-todo-container{
    display: flex;    
    justify-content: space-between;
    margin-top:40px;
}


.input{
    outline: none;
    border: none;
    box-shadow: inset 0px 0px 2px #e0e0e0;
    padding: 15px 20px;
    border-radius: 36px;
    flex-grow: 1;
    margin-right: 10px;
}

.button{
    border-radius: 36px;
    padding: 15px 20px;
    border: none;
    background: blue;
    color: white;
    transition: ease-in .2s;
}

.button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.errMessage{
    font-size: 12px;
    padding: 10px;
    height: 34px;
    text-align: left;
    color: crimson;
}
