.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 1px 2px gainsboro;
    border-radius: 5px;
    margin-top: 20px;
    transition: 1s;

    &:hover{
        box-shadow: 0px 1px 1px green;
    }

    .todo-buttons{
        button{
            font-size: 14px;
            border: none;
            margin-left: 10px;
            padding: 5px 10px;

            &:hover{
                cursor: pointer;
            }
        }
        .complete-btn{
            background-color: green;
            color: white;
        }

        .delete-btn{
            background-color: red;
            color: white;
        }
    }
}

.completed{
    background-color: green;
    color: white;    
    .todo-text{
        text-decoration: line-through;
    }

    .complete-btn{
        background-color: white !important;
        color: black !important;
    }
}